<template>
  <div class="mt-base">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No Media</label>
        <vs-input class="w-full" :value="initData.header.media.no_media" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Platform</label>
        <vs-input class="w-full" :value="initData.header.media.nama_platform" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">File</label>
        <div>
          <vx-input-group>
            <vs-input class="w-full" :value="initData.header.media.files_url ? (initData.header.media.files_url.length + ' File') : null" readonly/>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button type="filled" icon-pack="feather" icon="icon-image" :disabled="!initData.header.media.files_url || initData.header.media.files_url.length < 1" @click="showGallery(initData.header.media.files_url)">Preview</vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Judul</label>
        <vs-input class="w-full" :value="initData.header.media.judul" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Deskripsi</label>
        <div class="p-3 overflow-auto border border-solid d-theme-border-grey-light">
          <div v-html="initData.header.media.deskripsi"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css'
import _ from 'lodash'

export default {
  name: 'TabMedia',
  computed: {
    storePengajuanMediaShow () {
      return this.$store.state.marketing.pengajuanMediaShow
    },
    initData: {
      get () {
        return this.storePengajuanMediaShow.initData
      },
      set (value) {
        this.$store.commit('marketing/pengajuanMediaShow/SET_INIT_DATA', value)
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    showGallery (listMedia) {
      const lightbox = GLightbox()
      const elements = _.map(listMedia, item => ({ href: item }))
      lightbox.setElements(elements)
      lightbox.open()
    }
  }
}
</script>
